body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    min-height: -webkit-fill-available;
    background-color:#f7f7f7;
}
*, *::after, *::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}

/* Header */
.navDesktop{background-color:#fff;}
.mainLogo{max-width: 185px;
    margin-left: 50px;
    margin-bottom: -50px;margin-top: 15px;z-index: 10;position: relative;transform: rotate(-6deg);}
.headerLogin{color:#000;font-weight:700;text-transform: uppercase;font-size:14px;text-decoration: none;margin-left:auto;display:table;margin-right:15px;letter-spacing: 1px;}
.headerLogin:hover{color:#d40000;}
.tablet{background-color:#fff;display:none;}
.tablet .mainLogo{max-width:60px;}
.tablet .headerLogin{margin-right:75px;}
.headerLoginImage{display: inline-block;vertical-align: bottom;margin-right: 7px;}

/* Navigation */
.main-navigation{margin-top:0px;text-align:left;padding-left: 0px;padding-left:10px;}
.main-navigation li {
    display: block;
    position: relative;
    float: left;
}
.main-navigation li a {
    display: block;
    padding: 15px 25px;
    text-decoration: none;
    white-space: nowrap;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
}

.main-navigation .active a{
    color: #d40000;
}
.main-navigation li a:hover {
    color: #d40000;
}
.main-navigation li:hover > ul {
    display: inherit;
}
.main-navigation li:hover li {
    float: none;
}
.main-navigation ul ul {
    left: 100%;
    top: 0;
    border-top:none;
}
.dropdown-item:after {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    content: '';
    top: 22px;
    right: 5px;
}
.dropdown-item{margin-right:5px;}

.dropdown-menu{
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1;
    display:none;
    transform-origin: center top 0;
    transition: all .1s ease 0s;
    border-top: 5px solid #d40000;
    box-shadow: 0px 5px 8px rgba(0,0,0,.3);
    position: absolute;
    min-width: 225px;
}
.dropdown-menu li a{
    background-color: #fff;
    color: #000;
    font-size:14px;
    padding: 10px 20px;
}
.dropdown-menu li a:hover{
    background-color:#e7e7e7;
}
.dropdown-menu li {
    border-top: 1px solid black;
    margin-top: -1px;
}
.new{
    background: #d40000;
    color: white;
    font-size: 10px;
    border-radius: 7px;
    font-weight: 600;
    padding: 2px 7px;
    position: absolute;
    top: 4px;
    right: 2px;
    text-transform:uppercase;
    box-shadow: 0 2px 2px rgb(0 0 0 / 30%);
}




/* Burger */
.bm-burger-button {
    position: absolute;
    width: 42px;
    height: 30px;
    right: 20px;
    top: 18px;
    display:none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

.bm-menu a{color:#fff;text-decoration: none;font-size:16px;text-transform:uppercase;margin:20px 0px;font-weight: 700;}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}



.titleCopy p{color:#d40000;text-transform: uppercase;font-weight:700;margin:0px;letter-spacing: 1px;}
.titleCopy h2{color:#000;margin:10px 0px;font-size:36px;line-height:100%;}
.titleCopy a{color: #d40000;
    text-transform: uppercase;
    font-size: 14px;
    padding: 10px 30px;
    border-radius: 25px;
    background-color: #fff;
    display: table;
    text-decoration: none;
    font-weight: 700;
    max-width: 200px;
    text-align: center;
    border: 2px solid #d40000;
    margin-top:20px;
}


/* Footer */
.footerFluid{background-color: #171717;text-align:center;padding-top:40px;padding-bottom:40px;}
.footerFluid ul{list-style-type: none;padding:0px;}
.footerFluid ul li{display:inline-block;}
.footerFluid ul li a{color:#fff;font-weight: 700;text-transform: uppercase;padding:10px;text-decoration: none;}
.footerLogo{max-width:150px;margin:30px auto;}
.footerFluidCopy{background-color:#000;text-align: center;}
.footerFluidCopy p{margin:0px;font-size:14px;color:gray;}
.footerFluidCopy p a{color:gray;}


.aboutContainer p a{color:#d40000;font-weight: bold;}
.aboutContainer{padding-top:40px;padding-bottom:60px;max-width:800px;}
.aboutContainer p{line-height: 170%;}
.aboutContainer ul li{line-height: 170%;}
.aboutContainer .quote{background: #d40000;color: white;font-weight:600;padding:30px;margin-top: 20px;}
.aboutContainer h2{margin-top: 30px;}


@media screen and (max-width:1199px) {
    .titleCopy h2 {
        font-size: 32px;
    }
    .titleCopy a{padding:10px;}
    .mainLogo{margin-left:15px;}
}

@media screen and (max-width:991px) {
    .navDesktop {
        display: none;
    }
    .bm-burger-button{display:inherit;}
    .tablet{display:inherit;}
    .mainLogo{margin:10px;max-width:50px;}
}

@media screen and (max-width:767px) {
    .footerFluid ul li {
        display: block;
        margin-bottom:15px;
    }
}
