.frontDealsContainer{padding-top:40px;padding-bottom:50px;}
.frontDealsContainer .titleCopy{text-align:center;}
.frontDealsContainer .titleCopy h2{margin-bottom:40px;font-size:46px;}
.loadMore{
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    padding: 15px 30px;
    border-radius: 25px;
    background-color: #d40000;
    display: table;
    text-decoration: none;
    font-weight: 700;
    max-width: 230px;
    text-align: center;
    border: 2px solid #d40000;
    margin:30px auto;
    cursor: pointer;
}
.loadMore:hover{
    background-color: #fff;
    color: #d40000;
}