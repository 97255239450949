
.homeHero{background-image:url('../images/hero-back-min.jpg');min-height:500px;padding:50px;margin-top:15px;border-radius:6px;display:flex;justify-content: center;flex-direction:column;background-size:cover;background-position: center;}
.homeHero h1{margin:0px;color:#fff;font-size:75px;max-width:700px;line-height:90%;}
.homeHero p{font-size: 18px;
    line-height: 170%;
    font-weight: 600;
    max-width: 650px;color:#fff;margin:20px 0px;}
.homeHero a{color:#d40000;text-transform: uppercase;font-size:16px;padding:18px 20px;border-radius:50px;background-color:#fff;display:table;text-decoration:none;font-weight:700;max-width:230px;text-align:center;border:2px solid #d40000;}
.homeHero a:hover{color: #fff;background-color:#d40000;}

@media screen and (max-width:1199px) {
    .homeHero{box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);}
}


@media screen and (max-width:767px){
    .homeHero{min-height: 100%;padding:30px;}
    .homeHero h1{font-size:36px;}
}