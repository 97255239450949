.newsLetter{background-image:url('../images/subscribe-back-min.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;min-height:350px;margin-top:50px;margin-bottom: -15px;}
.newsLetter h2{color:#fff;text-align: center;margin:0px;font-size:40px;}
.newsLetter p{color:#fff;text-align: center;line-height:160%;font-weight:500;margin-top:10px;}
.newsLetter p a{color:#fff;font-weight:bold;}
.newsLetterForm{text-align:center;margin-top:20px;}
.newsLetterForm input{border-radius:25px;padding:13px 20px;border:none;width:700px;}
.newsLetterForm button{
    position: absolute;
    margin-left: -40px;
    z-index: 10;
    margin-top: 9px;
    cursor:pointer;
}



@media screen and (max-width:991px){
    .newsLetterForm input{width:80%;}
    .newsLetter{padding:30px;}
    .newsLetter h2{line-height:110%;}
}

@media screen and (max-width:767px){
    .newsLetter{min-height:100%;margin-top: 0px;margin-bottom: 15px;background-position: left;}
}